import { useAuth0 } from "@auth0/auth0-react";
import { ModifyButtonLarge } from "common/ui/buttons";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <ModifyButtonLarge onClick={() => loginWithRedirect()}>
      {_("Login with SSO")}
    </ModifyButtonLarge>
  );
};
