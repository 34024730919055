import { ActionButtonLarge } from "common/ui/buttons";

interface PropTypes {
  title: string;
  message: string;
  goToLogin?: () => any;
}

export const SuccessMessage = ({ title, message, goToLogin }: PropTypes) => {
  const footer = goToLogin ? (
    <footer>
      <ActionButtonLarge
        type="button"
        className="x-go-to-login qa-go-login-button"
        onClick={goToLogin}
      >
        {_("Go to login")}
      </ActionButtonLarge>
    </footer>
  ) : undefined;

  return (
    <div className="x-success">
      <fieldset>
        <section>
          <h2>
            {title} <i className="fa fa-check text-success" />
          </h2>
        </section>
        <section>
          <span>{message}</span>
        </section>
      </fieldset>
      {footer}
    </div>
  );
};
