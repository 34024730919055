import { ChangeEvent } from "react";
import { withValue, WithValue } from "common/with-value";

interface PropTypes {
  icon?: string;
  type?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  disabled?: boolean;
}

const InputComp = ({
  icon,
  type = "text",
  autoFocus,
  value,
  onChangeSetValue,
  autoComplete,
  disabled = false,
}: PropTypes & WithValue<string>) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeSetValue(e.target.value);
  };

  return (
    <label className="x-input-with-icon">
      {icon ? <i className={`fa ${icon}`} /> : undefined}
      <input
        type={type}
        disabled={disabled}
        autoFocus={autoFocus}
        value={value || ""}
        onChange={onInputChange}
        autoComplete={autoComplete}
      />
    </label>
  );
};

export const Input = withValue<string, PropTypes>(InputComp, "Input");
