import { ToastContainer } from "react-toastify";
import { getApiCallResolvingData } from "common/api";
import { apiCallFullWithLoginRedirect } from "common/api/impl";
import { getQueryString } from "common/app/query-string";
import { render } from "common/app/render";
import { Updater } from "common/app/updater";
import { getWindowLocation } from "common/utils/window-location";
import { LoginControllerWithSso } from "common/vendor-wrappers/auth0/controller";
import { Layout } from "./layout";

import "font-awesome/css/font-awesome.min.css";

import "./x-login.scss";

const apiCallResolvingData = getApiCallResolvingData(
  apiCallFullWithLoginRedirect,
);

const windowLocation = getWindowLocation();
const hostItems = windowLocation.hostname.split(".");
const queryString = getQueryString(windowLocation);
const { tenant, token, userName, action } = queryString;
const loginTenant =
  tenant || (hostItems.length > 1 && hostItems[0]) || undefined;
const tenantName = loginTenant !== "admin" ? loginTenant : undefined;

render(
  <Layout>
    <LoginControllerWithSso
      apiCall={apiCallResolvingData}
      userName={userName}
      tenantName={tenantName}
      token={token}
      action={action}
    />
    <Updater apiCall={apiCallResolvingData} />
    <ToastContainer theme={"light"} />
  </Layout>,
);
