import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { userAuthApi } from "common/api/authentication/user";
import { ApiCall } from "common/types/api";
import { CancellablePromise } from "common/types/promises";
import { trackLogin } from "common/utils/mixpanel";
import {
  AUTH_CONFIG,
  getLogoutParams,
} from "common/vendor-wrappers/auth0/config";
import { OnSuccess, Page } from "common/vendor-wrappers/auth0/types";
import { LoadingIcon } from "common/widgets/loading-icon";
import { SsoLoginForm } from "./form";

type Props = {
  apiCall: ApiCall;
  allowPasswordLogin: boolean;
  tenantName: string;
  preFilledUserName?: string;
  action?: string;
  onSuccess: OnSuccess;
  setPage: (page: Page) => void;
  onUserNameSave: (userName: string) => void;
};

export const SsoLogin = ({
  apiCall,
  tenantName,
  allowPasswordLogin,
  preFilledUserName,
  action,
  setPage,
  onUserNameSave,
  onSuccess,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout,
  } = useAuth0();

  const onLoading = useCallback((loading: boolean, message?: string) => {
    setLoading(loading);
    setLoadingMessage(message);
  }, []);

  const authenticate = useCallback(() => {
    onLoading(true, `${_("Loading")}...`);
    CancellablePromise.all([getAccessTokenSilently(), getIdTokenClaims()])
      .then(([accessToken, idToken]) => {
        userAuthApi(apiCall)
          .singleSignOn(accessToken, tenantName)
          .then(({ userTypes }) => {
            trackLogin(
              idToken[AUTH_CONFIG.namespace + "username"],
              tenantName,
              () => onSuccess(userTypes, false),
            );
          })
          .catch(() => onLoading(false, ""));
      })
      .catch(() => setPage("auth0login"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCall, onSuccess, setPage, tenantName, onLoading]); // adding getIdTokenClaims, getAccessTokenSilently leads to 1 additional run of this effect

  useEffect(() => {
    if (!isAuthenticated) return;
    authenticate();
  }, [authenticate, isAuthenticated]);

  useEffect(() => {
    if (action === "logout") {
      logout(getLogoutParams());
    }
  }, [action, logout]);

  if (isLoading || loading) return <LoadingIcon message={loadingMessage} />;
  return (
    <SsoLoginForm
      apiCall={apiCall}
      tenantName={tenantName}
      preFilledUserName={preFilledUserName}
      allowPasswordLogin={allowPasswordLogin}
      setPage={setPage}
      onUserNameSave={onUserNameSave}
    />
  );
};
