import { Component } from "react";
import { DisplayTenant } from "common/types/authentication";
import { ActionButton, ModifyButton } from "common/ui/buttons";
import { Selector } from "common/widgets/selector";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";

interface PropTypes {
  onTenant: (x: string) => any;
  onAdmin: () => any;
  loading: boolean;
  isSystemUser: boolean;
  displayTenants: DisplayTenant[];
  error: ApiErrorResponse;
}

export class SelectTenant extends Component<PropTypes> {
  static readonly displayName = "SelectTenant";

  onTenantChange = (tenant: string) => {
    const { onTenant } = this.props;
    onTenant(tenant);
  };

  onSandboxChange = (sandbox: DisplayTenant) => {
    const { onTenant } = this.props;
    onTenant(sandbox.name);
  };

  onClick = (tenant: string) => {
    return () => {
      const { onTenant } = this.props;
      onTenant(tenant);
    };
  };

  displaySandbox = (sandbox: DisplayTenant) => {
    return `${sandbox.name} (${sandbox.parentTenantName})`;
  };

  render() {
    const { onAdmin, loading, isSystemUser, displayTenants, error } =
      this.props;

    const mainTenantAccounts = displayTenants
      ?.filter((t) => !t?.parentTenantName)
      .map((t) => t.name);
    const sandboxAccounts = displayTenants?.filter((s) => s?.parentTenantName);

    const adminAppButton = isSystemUser ? (
      <ModifyButton
        className="qa-admin-button"
        disabled={loading}
        onClick={onAdmin}
      >
        {_("Administration Website")}
      </ModifyButton>
    ) : undefined;

    const title = loading ? (
      <section>
        {_("Authenticating")}
        <i className="fa fa-spin fa-spinner x-margin-left-5" />
      </section>
    ) : (
      <section>{_("Production Account")}</section>
    );

    const tenantButtons =
      mainTenantAccounts?.length >= 5 ? (
        <Selector
          value={undefined}
          options={mainTenantAccounts}
          placeholder={`${_("Select an account")}...`}
          onChange={this.onTenantChange}
        />
      ) : (
        mainTenantAccounts?.map((t, i) => (
          <ActionButton
            key={i}
            className="qa-tenant-button"
            disabled={loading}
            onClick={this.onClick(t)}
          >
            {t}
          </ActionButton>
        ))
      );

    const sandboxButtons =
      sandboxAccounts?.length >= 5 ? (
        <Selector
          value={undefined}
          options={sandboxAccounts}
          placeholder={`${_("Select a sandbox")}...`}
          getOptionLabel={this.displaySandbox}
          onChange={this.onSandboxChange}
        />
      ) : (
        sandboxAccounts?.map((s) => (
          <ActionButton
            key={s.name}
            className="qa-tenant-button"
            disabled={loading}
            onClick={this.onClick(s.name)}
          >
            {`${s.name} (${s.parentTenantName})`}
          </ActionButton>
        ))
      );

    const sandboxSection = sandboxAccounts?.length ? (
      <div className="x-select-tenant">
        <div className="wrapper-or">
          <hr />
          <div className="or">{_("OR")}</div>
          <hr />
        </div>
        <i className="fa fa-cube x-margin-right-5" />
        {_("Sandbox/Test Account")}
        {sandboxButtons}
      </div>
    ) : undefined;

    return (
      <div className="x-select-accounts">
        <div className="x-select-tenant">
          {error ? <ApiError error={error} /> : undefined}
          {adminAppButton}
          {title}
          {tenantButtons}
        </div>
        {sandboxSection}
      </div>
    );
  }
}
