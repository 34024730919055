import { RequestValue } from "common/types/authentication";
import { ApiErrorResponse } from "common/types/error";
import { ApiError } from "common/ui/api-error";
import { ActionButtonLarge, CancelButtonLarge } from "common/ui/buttons";
import { email as isEmail } from "common/validate";
import { Email } from "common/widgets/email";
import { withValue, WithValue } from "common/with-value";

interface PropTypes {
  onRequest: () => any;
  onCancel: () => any;
  error: ApiErrorResponse;
}

const RequestFormComp = ({
  onRequest,
  onCancel,
  error,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<RequestValue>) => {
  const { userName } = value;
  const isValid = userName && isEmail(userName);

  const onSubmit = (e: any) => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    if (isValid) onRequest();
    return false;
  };

  return (
    <form className="x-request-form" onSubmit={onSubmit}>
      <fieldset>
        <section className="qa-title">
          <h2>{_("Request New Password")}</h2>
        </section>
        <section className="x-margin-bottom-10 qa-message">
          <span>
            {`${_(
              "Please provide your email address to reset your password",
            )}.`}
          </span>
        </section>
        <section className="qa-email-input">
          <Email
            value={userName || ""}
            validate={true}
            onChange={onChangeMergeValue("userName")}
          />
        </section>
        {error && <ApiError error={error} className="x-margin-top-10-i" />}
      </fieldset>
      <footer>
        <CancelButtonLarge
          className="x-button-cancel-large qa-cancel-button"
          type="button"
          onClick={onCancel}
        >
          {_("Cancel")}
        </CancelButtonLarge>
        <ActionButtonLarge
          type="submit"
          className="qa-request-button"
          disabled={!isValid}
        >
          {_("Send Request")}
        </ActionButtonLarge>
      </footer>
    </form>
  );
};

export const RequestForm = withValue<RequestValue, PropTypes>(
  RequestFormComp,
  "RequestForm",
);
