import * as React from "react";
import { useState } from "react";
import { ApiErrorResponse } from "common/types/error";
import { AlertError } from "common/widgets/alert";
import { LoadingButton } from "common/widgets/loading-button";
import { Input } from "./input";

interface PropTypes {
  loading: boolean;
  error: ApiErrorResponse;
  onVerifyCode: (code: string) => void;
  onAuthenticationCodeChange: () => void;
}

export const MultiFactorAuthForm = ({
  loading,
  error,
  onVerifyCode,
  onAuthenticationCodeChange,
}: PropTypes) => {
  const [code, setCode] = useState("");

  const onSubmit = (e: React.FormEvent) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    onVerifyCode(code);
  };

  const onChange = (code: string) => {
    setCode(code);
    onAuthenticationCodeChange();
  };

  return (
    <form className="x-multi-factor-auth-form" onSubmit={onSubmit}>
      <fieldset>
        <section>
          <div className="x-verify-code-header">
            {_("Two-step authentication")}
          </div>
          <label className="label">
            {_(
              "Please provide your Multi-Factor Authentication Code to login.",
            )}
          </label>
          <Input
            type="text"
            autoFocus={true}
            value={code}
            onChange={onChange}
            autoComplete="off"
          />
        </section>
        {error ? (
          <AlertError
            className="x-margin-top-10-i"
            message={_("Invalid authentication code.")}
          />
        ) : undefined}
      </fieldset>
      <footer className="x-clear-after">
        <LoadingButton
          size="large"
          className="x-float-right qa-authentication-code-confirm-button"
          type="action"
          htmlButtonType="submit"
          idleText={_("Confirm")}
          loadingText={_("Confirming")}
          disabled={!code}
          loading={loading}
        />
      </footer>
    </form>
  );
};
