import { useEffect, useState } from "react";
import { ApiCall } from "common/types/api";
import { ActionButtonLarge } from "common/ui/buttons";
import { email as isEmail } from "common/validate";
import { Email } from "common/widgets/email";
import { Logo } from "common/widgets/logo";

import { userAuthApi } from "common/api/authentication/user";
import { AlertErrorTip, AlertInfo } from "common/widgets/alert";
import { Page } from "common/vendor-wrappers/auth0/types";
import { LoginButton } from "./login-button";

import "./_index.scss";

type PropTypes = {
  apiCall: ApiCall;
  tenantName?: string;
  preFilledUserName: string;
  allowPasswordLogin: boolean;
  setPage: (page: Page) => void;
  onUserNameSave: (userName: string) => void;
};

type MessageType = "info" | "error";

interface MessageState {
  type: MessageType;
  text: string;
}

export const SsoLoginForm = ({
  tenantName,
  preFilledUserName,
  allowPasswordLogin,
  apiCall,
  setPage,
  onUserNameSave,
}: PropTypes) => {
  const [message, setMessage] = useState<MessageState>(undefined);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (preFilledUserName) setUserName(preFilledUserName);
  }, [preFilledUserName]);

  const canUsePasswordAuth = async () => {
    try {
      return await userAuthApi(apiCall).canUsePasswordAuth({
        tenant: tenantName,
        userName,
      });
    } catch {
      return false;
    }
  };

  const isEmailValid = isEmail(userName);

  const onSubmit = async (e: any) => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();

    if (!userName) {
      setMessage({ text: _("Enter email address"), type: "error" });
      return false;
    }

    if (!isEmailValid) return false;
    const canLogin = await canUsePasswordAuth();

    if (!canLogin) {
      setMessage({
        type: "info",
        text: _(
          "You are not permitted to use Password-based login. Please continue with SSO or speak to your administrator.",
        ),
      });
      return false;
    }

    onUserNameSave(userName);
    setPage("x5login");
    return true;
  };

  const renderMessageState = () => {
    const { type, text } = message || {};
    switch (type) {
      case "error":
        return <AlertErrorTip message={text} />;
      case "info":
        return <AlertInfo message={text} />;
      default:
        return undefined;
    }
  };

  return (
    <form className="x-sso-login-form" onSubmit={onSubmit}>
      <fieldset>
        <section>
          <Logo tenantName={tenantName} />
        </section>
        <section>
          <LoginButton />
        </section>
        {allowPasswordLogin ? (
          <>
            <section className="wrapper-or">
              <hr />
              <div className="or">{_("OR")}</div>
              <hr />
            </section>
            <section className="qa-username-input">
              <label className="label">{_("E-mail")}</label>
              <Email
                validate={true}
                value={userName}
                onChange={(userName) => {
                  setUserName(userName);
                  setMessage(undefined);
                }}
              />
              {renderMessageState()}
            </section>
            <section className="x-padding-top-20">
              <ActionButtonLarge type="submit" className="x-continue">
                {_("Continue")}
              </ActionButtonLarge>
            </section>
          </>
        ) : undefined}
      </fieldset>
    </form>
  );
};
