import { validatePassword } from "common/api/authentication/user";
import { getError } from "common/api/error";
import { ResetValue } from "common/types/authentication";
import { ApiErrorResponse } from "common/types/error";
import { PasswordPolicy } from "common/types/settings";
import { ActionButtonLarge } from "common/ui/buttons";
import { AlertWarning } from "common/widgets/alert";
import { withValue, WithValue } from "common/with-value";
import { Input } from "./input";

export interface ResetFormValue extends ResetValue {
  confirmedPassword: string;
}

interface PropTypes {
  passwordPolicy: PasswordPolicy;
  onReset: () => any;
  loading: boolean;
  expired: boolean;
  error: ApiErrorResponse;
}

const ResetFormComp = ({
  onReset,
  passwordPolicy,
  loading,
  expired,
  error,
  value,
  onChangeMergeValue,
}: PropTypes & WithValue<ResetFormValue>) => {
  const { newPassword, confirmedPassword, userName } = value;

  const policyError = validatePassword(passwordPolicy, newPassword, userName);

  const passwordPolicyError = policyError && (
    <section>
      <AlertWarning message={policyError} />
    </section>
  );

  const hasConfirmError =
    newPassword && confirmedPassword && newPassword !== confirmedPassword;

  const passwordConfirmError = hasConfirmError && (
    <section>
      <AlertWarning
        message={_("Passwords do not match")}
        className="x-margin-top-10-i"
      />
    </section>
  );

  const isValid =
    newPassword &&
    confirmedPassword &&
    newPassword === confirmedPassword &&
    !policyError;

  const button = loading ? (
    <ActionButtonLarge className="qa-reset-button" disabled={true}>
      {_("Resetting")}
      <i className="fa fa-spin fa-spinner x-margin-left-5" />
    </ActionButtonLarge>
  ) : (
    <ActionButtonLarge
      type="submit"
      className="qa-reset-button"
      disabled={!isValid}
    >
      {_("Reset")}
    </ActionButtonLarge>
  );

  const onSubmit = (e: any) => {
    if (e.preventDefault) e.preventDefault();
    if (e.stopPropagation) e.stopPropagation();
    onReset();
    return false;
  };

  const msg = expired ? `${_("Your password has expired")}.` : "";

  return (
    <form className="x-reset-form" onSubmit={onSubmit}>
      <fieldset>
        <section>
          <h2>{_("Create New Password")}</h2>
        </section>
        <section>
          <span>
            {_("MSG Please set your new password").replace("MSG", msg)}
          </span>
        </section>

        <section>
          <Input
            type="password"
            icon="fa-lock"
            autoFocus={true}
            value={newPassword}
            onChange={onChangeMergeValue("newPassword")}
            autoComplete="off"
          />
        </section>
        {passwordPolicyError}
        <section className="x-margin-top-10">
          <label className="label">{_("Confirm Password")}</label>
          <Input
            type="password"
            icon="fa-lock"
            value={confirmedPassword}
            onChange={onChangeMergeValue("confirmedPassword")}
            autoComplete="off"
          />
        </section>
        {passwordConfirmError}
        {error && (
          <AlertWarning
            message={getError(error)}
            className="x-margin-top-10-i"
          />
        )}
      </fieldset>
      <footer>{button}</footer>
    </form>
  );
};

export const ResetForm = withValue<ResetFormValue, PropTypes>(
  ResetFormComp,
  "ResetForm",
);
