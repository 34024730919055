import * as React from "react";
import { MouseEvent } from "react";
import { DISABLED_VENDOR_PORTAL, getError } from "common/api/error";
import { LoginValue } from "common/types/authentication";
import { ApiErrorResponse } from "common/types/error";
import { CancelButtonLarge } from "common/ui/buttons";
import { AlertError } from "common/widgets/alert";
import { LoadingButton } from "common/widgets/loading-button";
import { WithValue, withValue } from "common/with-value";
import { Input } from "./input";

interface PropTypes {
  onForgotClick: (e: MouseEvent) => any;
  onLogin: (tenant: string) => any;
  loading: boolean;
  error: ApiErrorResponse;
  withGoBack?: boolean;
  goBackFn?: () => void;
}

const NUMBER_OF_ATTEMPTS = 3;

const getErrorMessage = (
  userName: string,
  password: string,
  error: ApiErrorResponse,
) => {
  if (!userName || !password) return _("Username and Password are required");
  if (error?.status === 401)
    return _(
      "Either your Username or Password is incorrect. Your account may lockout after '{NUMBER_OF_ATTEMPTS}' incorrect attempts. Please contact Support if further assistance is required.",
    ).replace("{NUMBER_OF_ATTEMPTS}", NUMBER_OF_ATTEMPTS.toString());

  return getError(error);
};

const LoginFormComp = ({
  onLogin,
  onForgotClick,
  loading,
  value,
  goBackFn,
  error,
  onChangeMergeValue,
  withGoBack,
}: PropTypes & WithValue<LoginValue>) => {
  const { userName, password, tenant } = value;

  const onSubmit = (e: React.FormEvent) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    onLogin(tenant);
  };

  const recoverLink = (
    <div className="x-forgot-password">
      <a href="#" onClick={onForgotClick} className="qa-forgot-password">
        {`${_("Forgot password")}?`}
      </a>
    </div>
  );

  return (
    <form className="x-login-form" onSubmit={onSubmit}>
      <fieldset>
        <section className="qa-username-input">
          <label className="label">{_("E-mail")}</label>
          <Input
            type="text"
            icon="fa-user"
            autoFocus={true}
            disabled={withGoBack}
            value={userName}
            onChange={onChangeMergeValue("userName")}
          />
        </section>
        <section className="x-margin-top-10 qa-password-input">
          <label className="label">{_("Password")}</label>
          <Input
            type="password"
            icon="fa-lock"
            value={password}
            onChange={onChangeMergeValue("password")}
            autoComplete="off"
          />
          {recoverLink}
        </section>
        {error && error?.data?.error !== DISABLED_VENDOR_PORTAL ? (
          <AlertError
            className="x-margin-top-10-i"
            message={getErrorMessage(userName, password, error)}
          />
        ) : undefined}
      </fieldset>
      <footer className="x-footer">
        {withGoBack && (
          <CancelButtonLarge className="return-back" onClick={goBackFn}>
            {_("Previous Page")}
          </CancelButtonLarge>
        )}
        <LoadingButton
          size="large"
          className="sign-in-button qa-sign-in-button"
          type="action"
          htmlButtonType="submit"
          idleText={_("Sign in")}
          loadingText={_("Authenticating")}
          loading={loading}
        />
      </footer>
    </form>
  );
};

export const LoginForm = withValue<LoginValue, PropTypes>(
  LoginFormComp,
  "LoginForm",
);
